import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import style from "./dynamic_button.module.css";

function DynamicButton(props) {
  const { callback, value, isSelected } = props;

  const backgroundAnim = useAnimation();
  const textAnim = useAnimation();
  const textProps = {
    start: {
      letterSpacing: 0.4 + "em",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    end: {
      letterSpacing: 0.1 + "em",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const underlineAnim = useAnimation();
  const underlineProps = {
    start: {
      width: 10 + "vw",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    end: {
      width: 15 + "px",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const handleHoverStart = () => {
    underlineAnim.start(underlineProps["start"]);
    textAnim.start(textProps["start"]);
  };

  const handleHoverEnd = () => {
    if (!isSelected) {
      underlineAnim.start(underlineProps["end"]);
      textAnim.start(textProps["end"]);
    }
  };

  useEffect(() => {
    if (isSelected) {
      handleHoverStart();
    } else {
      setTimeout(handleHoverEnd, 700);
    }
  });

  return (
    <motion.div
      whileHover={{
        scale: 1.1,
        transition: { duration: 0.2 },
      }}
      whileTap={{
        backgroundColor: "rgba(0, 0, 0, 0.322)",
        borderRadius: 20 + "px",
        padding: "0px 10px 0px 30px",
      }}
      onTap={(e) => callback(value)}
      onHoverStart={(e) => handleHoverStart()}
      onHoverEnd={(e) => handleHoverEnd()}
      style={{ backgroundColor: "transparent" }}
    >
      <div className={style.buttonBackground}>
        <motion.div animate={textAnim} className={style.buttonText}>
          <p>{props.children}</p>
        </motion.div>
        <motion.div
          animate={underlineAnim}
          className={style.underline}
        ></motion.div>
      </div>
    </motion.div>
  );
}
export default DynamicButton;
