import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import style from "./text_carrousel.module.css";
import data from "../resources/about.json";
import TextParser from "./text_parser";

import DynamicButton from "../general/dynamic_button";

function TextCarousel() {
  var topics = [];
  Object.keys(data).forEach((key) => {
    topics.push(key);
  });

  const textAnim = useAnimation();
  const textProps = {
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeInOut",
      },
    },
    hide: {
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
  };

  const [index, setIndex] = useState(0);

  const bootStyles = {
    grid: {
      paddingTop: 0, 
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
    },
  };

  const callback = async (value) => {
    await textAnim.start(textProps.hide);
    setIndex(value);
    textAnim.start(textProps.show);
  };

  return (
    <div className={style.backdrop}>
    <Container fluid={true} style={bootStyles.grid}>
        <Row className={style.headerColor}>
          <div className={style.aboutTitle}>
            <h1>Who we are.</h1>
          </div>
        </Row>
        <Row>
          <Col style={bootStyles.col} xs={1.5}>                                  
            <div className={style.aboutOptions}>
              {topics.map((item, itemIndex) => (
                <ul>               
                  <DynamicButton
                    callback={callback}
                    value={itemIndex}
                    isSelected={itemIndex == index}                
                  >
                    {item}
                  </DynamicButton>
                </ul>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
              ))}
            </div>
          </Col>
          <Col>
            <motion.div animate={textAnim}>
              <TextParser data={data[topics[index]]} />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default TextCarousel;
