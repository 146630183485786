import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import style from "./footer.module.css";
class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={style.footer}>
        <Container className={style.content}>
          <Row>
            <Col>
              <h4>Kashif</h4>
              <ul>
                <a href="https://www.youtube.com/completelyrezz">YouTube</a>
              </ul>
              <ul>
                <a href="https://twitter.com/KashifIV">Twitter</a>
              </ul>
            </Col>
            <Col>
              <h4>Michelle</h4>
              <ul>
                <a href="https://www.instagram.com/maz.uri/">Instagram</a>
              </ul>
              <ul>
                <a href="https://www.deviantart.com/mazzuri">Deviant Art</a>
              </ul>
            </Col>
          </Row>
        </Container>
        <p>Apothem Studios 2020</p>
      </div>
    );
  }
}

export default Footer;
