export const titleProps={
    hidden: {
        top: 50+ '%', 
        x: 0, 
        transition: {
            duration: 0.4,
            ease: 'easeInOut'
        }

    }, 
    reveal: {
        top: 50+ '%', 
        x:  window.innerWidth/6- window.innerWidth/2 ,
        transition: {
            duration: 0.4,
            ease: 'easeInOut'
        }
    },
    lift: {
        top: 0+ '%', 
        fontSize: '20vh',   
        transition: {
            duration: 0.4,
            ease: 'easeInOut'
        }
    },
    pull: {
        top: 50+ '%', 
        fontSize: '40vh',   
        transition: {
            duration: 0.4,
            ease: 'easeInOut'
        }
    }
}; 
export const backgroundProps={
    hidden: {
        x: 0, 
        opacity: 1,  
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }

    }, 
    reveal: {
        x: window.innerWidth/2,
        opacity: 0,  
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}; 
export const fogProps={
    hidden:{
        opacity: 1
    }, 
    reveal:{
        opacity: 0, 
        transition: {
            duration: 0.4,
            ease: 'easeInOut'
        }
    }
}; 
