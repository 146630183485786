import React from 'react'; 
import {Container, Row, Col} from 'react-bootstrap'
import style from './temporary.module.css'; 


class Temporary extends React.Component{
  info(){
    return <div>
      <h1>Coming Soon.</h1>
      <p>{"Thanks for visiting, we\'re working on some exciting projects we can\'t wait to share. Problem is it\'s going to take some work. Until then please follow our work and reach out! We\'d love to hear from you."} </p>
      <Row>
        <Col>
          <h4>Kashif</h4>
          <a href="https://www.youtube.com/completelyrezz">YouTube</a>
          <br/>
          <a href="https://twitter.com/KashifIV">Twitter</a>

        </Col>
        <Col>
          <h4>Michelle</h4>

          <a href="https://www.instagram.com/maz.uri/">Instagram</a>
          <br/>
          <a href="https://www.deviantart.com/mazzuri">Deviant Art</a>

        </Col>
      </Row> 
    </div>
  }

  large(){
    return <div>
      <Col>
        {this.info()}
      </Col>
      <Col>
        
      </Col>
    </div>
  }


  render(){
    return <div>
      <div className={style.tempSection}>
        <Container>
          <Row>
            {window.innerWidth < 100 ? this.info() : this.large()}
          </Row>
        </Container>
      </div>
      <div className={style.image}>
        <div className={style.layer}/>
        <img className={style.backgroundImage} src='/images/MusashiRenderweb.jpg'/>
      </div>

    </div>
  }
}

export default Temporary; 