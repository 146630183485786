import React, {useState, useEffect} from 'react'; 
import {motion, useAnimation, motionValue} from 'framer-motion'; 
import {titleProps, backgroundProps, fogProps} from './sectionHeaderAnimData'; 
import {Container, Row, Col} from 'react-bootstrap';
import style from './sectionHeader.module.css'; 

import ProjectShowcaseDesktop from '../projectShowcase/desktop/projectShowcaseDesktop'; 

function SectionHeader(props){

    const {title, image, data} = props; 


    const [display, setDisplay] = useState('visible'); 

    const titleAnim = useAnimation(); 
    const backgroundAnim = useAnimation(); 
    const fogAnim = useAnimation(); 

    useEffect(()=>{
        document.addEventListener('scroll', trackScrolling); 
    }); 

    const trackScrolling = () => {
        if (document.getElementById(`${title}Header`).getBoundingClientRect().bottom <= window.innerHeight && display == 'visible'){
            onReveal(); 
        }
    }; 

    const onReveal = async () => {
        backgroundAnim.start(backgroundProps.reveal); 
        fogAnim.start(fogProps.reveal); 
        await titleAnim.start(titleProps.reveal); 
        await titleAnim.start(titleProps.lift); 
        setDisplay('hidden'); 
        document.removeEventListener('scroll', trackScrolling); 
    }; 
    const onClick = () => {

    }; 

    return(
        <motion.div>
            <div id={`${title}Header`} className={style.sectionHeader}>
                <div>
                    <motion.h1 animate={titleAnim} className={style.sectionTitle}>{title}</motion.h1> 
                </div>
                <motion.div animate={fogAnim} style={{visibility:display}} className={style.fog}/>
                <motion.div animate={backgroundAnim} style={{visibility:display, zIndex: 2}}>
                    <img animate={backgroundAnim} src={image}/> 
                </motion.div>

                <div className={style.collapse}>
                    <ProjectShowcaseDesktop json={data}/>
                </div> 
            </div>
        </motion.div>
    ); 
    
}
export default SectionHeader; 