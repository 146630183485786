import React from "react";
import Lottie from "react-lottie";
import animationData from "../animations/climbing_cut.json";
import { Container, Row, Col } from "react-bootstrap";
import TextCarousel from "./text_carousel";
import style from "./about.module.css";
import { motion } from "framer-motion";

class About extends React.Component {
  constructor(props) {
    super(props);
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData,
    };
    this.state = { animationOptions: defaultOptions, isStopped: true };
    this.carrouselRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }
  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      scrollPosition: scrolled,
    }); 
    
    if (scrolled > 0.2) {
      this.setState({
        isStopped: false,
      });
    }
  };
  render() {
    const bootStyles = {
      grid: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0, 
        paddingBottom: 0
      },
      row: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0, 
        marginBottom: 0, 
        paddingTop: 0
      },
      col: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0, 
        paddingBottom: 0
      },
    };
    const initial = {
      right: 29 + '%',
      opacity: 0.2,
    };
    const end = {
      right: 0 + '%',
      opacity: 1,
    };
    return (
      <div id="aboutPage" className={style.climbBackground}>
        <Container fluid={true} style={bootStyles.grid}>
          <Row style={bootStyles.row} noGutters={true}>
            <Col style={bootStyles.col} noGutters={true}>
              <div className={style.climbAnimation} id="image">
                <Lottie
                  options={this.state.animationOptions}
                  isStopped={this.state.isStopped}
                />
              </div>
            </Col>
            { (window.innerWidth > 1585) ? ( <Col style={bootStyles.col}>
              <motion.div
                animate={this.state.isStopped ? initial : end}
                style={{marginTop: '10vh'}}
                transition={{ ease: "easeInOut", duration: 1.4 }}
              >
                <TextCarousel ref={this.carrouselRef}/>
              </motion.div>
            </Col>) : <div/>}
          </Row>
          { (window.innerWidth <= 1585) ? ( <Row noGutters={true} style={bootStyles.row}>
              <motion.div
                animate={this.state.isStopped ? initial : end}
                transition={{ ease: "easeInOut", duration: 1.4 }}
                style={initial}
              >
                <TextCarousel ref={this.carrouselRef}/>
              </motion.div>
            </Row>) : <div/>}
        </Container>
      </div>
    );
  }
}
export default About;
