import React from 'react'; 
import style from './text_carrousel.module.css'; 

function TextParser(props){
    const {data} = props; 
    var texts = [];
    var textStyle = []; 

    const styles = {
        NONE: 'none', 
        BOLD: 'bold', 
        ITALIC: 'italic'
    } 

    data.forEach((value) => {
        texts.push(value['blob']); 
        if (value['style'] == styles.NONE){
            textStyle.push('p'); 
        }
        else if (value['style'] == styles.BOLD){
            textStyle.push('h4'); 
        }
        else {
            textStyle.push('h3'); 
        }
        texts.push(null); 
        textStyle.push('br'); 
    });
    return(
        <div className={style.text}>
            {
                textStyle.map((element, index) => 
                    React.createElement(element, null, texts[index])
                )
            }
        </div>
    );
}

export default TextParser; 