import React from "react";
import logo from "./logo.svg";
import { motion, useAnimation } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "./animations/full_intro.json";
import About from "./about/about";
import SectionHeader from "./section_header/sectionHeader";
import Footer from "./footer/footer";
import Temporary from "./temporary/temporary"; 
import "./App.css";

import animationHeader from './resources/animation.json'; 
import webMobile from './resources/webMobile.json'; 
import gameDev from './resources/gameDev.json'; 

function App() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
  };
  const animation = useAnimation();
  return (
    <div className="App">
      <header className="App-header">
        <Lottie
          options={defaultOptions}
          height={900}
          className={"apothem-animation"}
          eventListeners={[
            {
              eventName: "complete",
              callback: () =>
                animation.start({ opacity: 1, transition: { duration: 3 } }),
            },
          ]}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={animation}
          className="scroll-indicator"
        >
          <p>Scroll to begin our journey.</p>
          <img src="/images/Arrow.png" />
        </motion.div>
      </header>
      <Temporary/>
      {/* <About />
      <SectionHeader title="ANIMATION" image="/images/maki.png" data={animationHeader}>
        <temp />
      </SectionHeader>
      <SectionHeader title="WEB &amp; MOBILE" image="/images/mobile.png" data={webMobile}>
        <temp />
      </SectionHeader>
      <SectionHeader title="GAME DEVELOPMENT" image="/images/LEChars.png" data={gameDev}>
          <temp/>
      </SectionHeader>

      <Footer /> */}
    </div> 
  );
}

export default App;
